<template>
  <div class="py-5 overflow-hidden">
    <div class="row justify-content-center px-1 px-lg-4">
      <div class="col-lg-6 col-md-12">
        <div class="px-2 mx-md-6 mx-lg-2">
          <SearchPosts :posts="posts" />
          <SearchUsers :users="users" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import SearchPosts from "@/components/search/SearchPosts.vue"
import SearchUsers from "@/components/search/SearchUsers.vue"
export default {
  name: "Search",
  components: { SearchPosts, SearchUsers },
  data() {
    return {
      search: ""
    }
  },
  mounted() {
    this.search = this.$route.query.q
    this.searchPosts()
    this.searchUsers()
  },
  computed: {
    ...mapState("post", ["posts", "page"]),
    ...mapState("user", ["users", "profile"])
  },
  methods: {
    ...mapActions("post", ["getPosts"]),
    ...mapActions("user", ["getUsers"]),
    searchPosts() {
      this.getPosts({ page: 1, search: this.search })
    },
    searchUsers() {
      this.getUsers({ page: 1, search: this.search })
    }
  },
  watch: {
    search() {
      this.searchPosts()
      this.searchUsers()
    }
  }
}
</script>
