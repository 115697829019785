<template>
  <div
    class="pb-2"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true
    }"
  >
    <post-delete-modal :post_id="post.id"></post-delete-modal>
    <post-modal
      :user="user"
      :post="editedPost"
      :method="postMethod"
      ref="editPostModal"
    ></post-modal>
    <report-post-modal
      :post_id="post.id"
      :ref="'reportPostModal' + post.id"
    ></report-post-modal>
    <login-modal ref="loginModal"></login-modal>
    <div class="d-flex align-items-center justify-content-between px-3">
      <div class="d-flex align-items-center mt-3">
        <profile-image :user="post.user" class="me-3"></profile-image>
        <div class="d-flex flex-column pe-3 mt-2">
          <div class="poster-name align-self-start">
            <router-link
              :to="`/profile/${post.user.id}`"
              style="text-decoration: none; color: inherit"
            >
              {{ post.user.first_name }} {{ post.user.last_name }}
              <i
                class="bi bi-patch-check-fill text-success ms-1"
                v-if="post.user.email_verified_at != null"
              ></i>
            </router-link>
          </div>
          <div class="post-subtitle">
            <span class="me-3">
              <i class="bi bi-eye-fill"></i>{{ post.view_count }} Views
            </span>
            <span>{{ createdAt }}</span>
          </div>
        </div>
      </div>
      <div class="dropdown">
        <button
          class="option-btn btn d-flex align-items-center justify-content-center py-1 px-3"
          type="button"
          id="postActions"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="bi bi-three-dots-vertical"></i>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <button
              class="dropdown-item"
              @click="openPostModal"
              v-if="showOption(post)"
            >
              Edit Post
            </button>
          </li>
          <li>
            <button
              class="dropdown-item"
              data-bs-toggle="modal"
              :data-bs-target="'#postDeleteModal' + post.id"
              v-if="showOption(post)"
            >
              Move to trash
            </button>
          </li>
          <li>
            <button class="dropdown-item" @click="openReportPostModal">
              Report Post
            </button>
          </li>
        </ul>
      </div>
    </div>
    <p
      class="my-2 text-start post-description px-3"
      style="white-space: pre-line; overflow-wrap: break-word"
    >
      <span class="me-2" v-html="postDescription" v-linkified></span>
      <button
        type="button"
        class="btn btn-link shadow-none"
        style="text-decoration: none"
        @click="seeMore = !seeMore"
        v-if="canShowSeeMore"
      >
        <span v-if="seeMore">
          See less <i class="bi bi-chevron-compact-up"></i>
        </span>
        <span v-else>See more <i class="bi bi-chevron-compact-down"></i></span>
      </button>
    </p>

    <post-gallery
      :post-id="post.id"
      :data="postImgs"
      :img-plugins="imgPlugins"
    ></post-gallery>
    <div
      class="d-flex align-items-center justify-content-center overflow-hidden mt-2"
    >
      <video
        :id="'post-player' + post.id"
        controls
        muted
        class="cld-video-player cld-video-player-skin-dark cld-fluid my-4 px-2"
        v-if="post.video_path.length > 0"
      ></video>
    </div>
    <div class="d-flex align-items-center justify-content-between pt-2 px-3">
      <div
        class="d-flex align-items-center w-100"
        :class="notUser ? 'justify-content-between' : 'justify-content-end'"
      >
        <div v-if="notUser">
          <span v-if="isFollowing" class="text-primary" style="font-size: 12px">
            Following
          </span>
          <button class="btn follow-btn me-2" v-else @click="followUser">
            <span class="">+</span> <span class="mx-1">follow</span>
          </button>
        </div>
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center py-2 me-2">
            <button
              class="action-btn d-flex align-items-center justify-content-center"
              @click="toggleLike(post.id)"
            >
              <i
                v-if="hasLiked"
                class="bi bi-heart-fill"
                style="color: #ff0bb9"
              ></i>
              <i v-else class="bi bi-heart" style="color: #ff0bb9"></i>
            </button>
            <span class="count-text ms-1">{{ post.likes.length }}</span>
          </div>
          <div class="d-flex align-items-center py-2 ms-2">
            <button
              class="action-btn d-flex align-items-center justify-content-center"
              @click="showComments"
            >
              <i class="bi bi-chat-left me-2" style="color: #ff0bb9"></i>
              <span class="count-text">
                {{ post.comments.length }} Comments
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <comment-section
      v-if="isComments"
      :comments="post.comments"
      :post_id="post.id"
      :show-option="showOption"
    ></comment-section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import CommentSection from "@/components/post/CommentSection.vue"
import PostDeleteModal from "@/components/post/PostDeleteModal.vue"
import ReportPostModal from "@/components/post/ReportPostModal.vue"
import PostModal from "@/components/post/PostModal.vue"
import ProfileImage from "@/components/profile/ProfileImage.vue"
import { Modal } from "bootstrap"
import moment from "moment"
import { isAuth, getCloudinaryPId, loadScript } from "@/utils/misc"
import LoginModal from "@/components/LoginModal.vue"
import PostGallery from "@/components/post/PostGallery.vue"

import { lazyload, responsive, placeholder } from "@cloudinary/vue"

export default {
  name: "PostDetail",
  props: { post: Object, user: Object },
  components: {
    CommentSection,
    PostDeleteModal,
    PostModal,
    LoginModal,
    ReportPostModal,
    ProfileImage,
    PostGallery
  },
  data() {
    const postImgs = []
    if (this.post.image_path.length) {
      for (const image_path of this.post.image_path) {
        postImgs.push(this.cld.image(getCloudinaryPId(image_path)))
      }
    }

    const imgPlugins = [
      lazyload(),
      responsive(),
      placeholder({ mode: "pixelate" })
    ]

    const editablePost = {
      id: this.post.id,
      description: this.post.description,
      image_path: this.post.image_path,
      video_path: this.post.video_path
    }
    return {
      isComments: false,
      editedPost: JSON.parse(JSON.stringify(editablePost)),
      postMethod: "edit",
      seeMore: false,
      seeMoreLength: 130,
      postImgs,
      imgPlugins
    }
  },
  mounted() {
    // only initialize player if post is a video post
    if (this.post.video_path.length) {
      loadScript(this.initPlayer())
    }
  },
  created() {
    this.moment = moment
  },
  computed: {
    ...mapState("follower", ["following"]),
    ...mapState("user", ["user"]),
    isFollowing() {
      for (const follow of this.following) {
        if (follow.friend_id === this.post.user.id) {
          return true
        }
      }
      return false
    },
    /**
     * Verify if current user is the author of the post
     */
    notUser() {
      if (this.user?.id == this.post.user.id) {
        return false
      }
      return true
    },
    hasLiked() {
      if (!isAuth) {
        return false
      }
      return this.post.likes?.some((x) => x.user_id == this.user?.id)
    },
    postDescription() {
      if (this.seeMore) {
        return this.post.description
      }
      return this.post.description.substring(0, this.seeMoreLength)
    },
    canShowSeeMore() {
      return this.post.description.length >= this.seeMoreLength
    },
    createdAt() {
      let currentTime = moment()
      if (currentTime.diff(this.post.created_at, "h") > 24) {
        return moment(this.post.created_at).format("MMMM Do YYYY, h:mm:ss a")
      }
      return moment(this.post.created_at).fromNow()
    }
  },
  methods: {
    ...mapActions("post", ["editPost", "deletePost", "viewPost"]),
    ...mapActions("like", ["togglePostLike"]),
    ...mapActions("follower", ["follow"]),
    showOption(item) {
      if (isAuth() && item.user.id == this.user?.id) {
        return true
      }
      return false
    },
    showComments() {
      this.isComments = !this.isComments
    },
    openPostModal() {
      const modal = new Modal(this.$refs.editPostModal.$refs.postModal)
      modal.show()
    },
    openLoginModal() {
      const modal = new Modal(this.$refs.loginModal.$refs.loginModal)
      modal.show()
    },
    openReportPostModal() {
      if (!isAuth()) {
        this.openLoginModal()
        return
      }
      const modal = new Modal(
        this.$refs[`reportPostModal${this.post.id}`].$refs.reportPostModal
      )
      modal.show()
    },
    toggleLike(post_id) {
      if (!isAuth()) {
        this.openLoginModal()
        return
      }
      this.togglePostLike({ id: post_id, user_id: this.user.id })
    },
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.viewPost({ id: this.post.id })
      }
    },
    getCloudinaryPId: getCloudinaryPId,
    followUser() {
      if (!isAuth()) {
        this.openLoginModal()
        return
      }
      this.follow({ friend_id: this.post.user.id })
    },
    getPlaylistVids() {
      let videos = []
      for (const url of this.post.video_path) {
        videos.push(this.getCloudinaryPId(url))
      }
      return videos
    },
    initPlayer() {
      const playlistVids = this.getPlaylistVids()
      const logUrl =
        "https://res.cloudinary.com/dkj13ry1j/image/upload/v1688931206/Propelond_Logo2_qwybsb.png"
      const vPlayer = window.cloudinary.videoPlayer(
        `post-player${this.post.id}`,
        {
          cloud_name: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
          secure: true,
          logoImageUrl: logUrl,
          logoOnclickUrl: "https://www.propelond.com/",
          playbackRates: [0.25, 0.5, 0.75, 1, 1.5, 1.25, 1.75, 2],
          colors: { accent: "#FF0DB8" },
          playlistWidget: {
            direction: "vertical",
            total: playlistVids.length
          }
        }
      )
      vPlayer.playlist(playlistVids, {
        autoAdvance: true,
        presentUpcoming: 5
      })
      vPlayer.transformation({ quality: "auto" })
    }
  }
}
</script>

<style scoped>
.action-btn {
  outline: none;
  border: none;
  background-color: #fff;
  font-size: 18px;
}
.post-subtitle {
  font-size: 0.9rem;
  color: #c7ccd1;
}
.post-description {
  font-weight: 400;
  font-size: 1rem;
  line-height: 21px;
}
.option-btn {
  background-color: #f5f5f5;
  border-radius: 100px;
  width: 2rem;
  text-align: center;
  padding: 0px;
}
.poster-name {
  font-weight: bold;
  margin-bottom: -0.1rem;
}

.follow-btn {
  border: 1px solid #6d6d6d;
  border-radius: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #6d6d6d;
}
.count-text {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  align-items: center;
  color: #6d6d6d;
}
</style>
