<template>
  <div
    class="modal fade"
    id="commentDeleteModal"
    tabindex="-1"
    aria-labelledby="commentDeleteModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="commentModalLabel">Delete Comment</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <form @submit.prevent="submit">
          <div class="modal-body">
            <div class="mb-3">
              {{ message }}
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Delete
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "CommentDeleteModal",
  props: {
    deletedItem: Object
  },
  data() {
    return {
      id: null,
      message: "Are you sure you want to delete this comment?"
    }
  },
  created() {
    this.item = Object.assign({}, this.deletedItem)
  },
  methods: {
    ...mapActions("comment", ["deleteComment"]),
    submit() {
      this.$emit("reset-delete")
      this.deleteComment(this.item)
    }
  },
  watch: {
    deletedItem(data) {
      this.item = Object.assign({}, data)
    }
  }
}
</script>
