<template>
  <div class="mt-2" v-if="posts">
    <div class="rounded-top bg-white shadow-sm mt-3 py-2"><b>Posts</b></div>
    <div
      class="rounded-3 bg-white shadow-sm mt-3"
      v-for="post in posts"
      :key="post.id"
    >
      <post-detail :post="post" :user="user"></post-detail>
    </div>
    <button
      type="button"
      class="btn btn-outline-primary my-4 w-100"
      @click="loadNextPage"
      v-if="page.last_page > page.current_page"
    >
      See more posts
      <i class="bi bi-arrow-up-right-square-fill"></i>
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import PostDetail from "@/components/post/PostDetail.vue"
export default {
  name: "SearchPosts",
  components: { PostDetail },
  props: {
    posts: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("post", ["page"])
  },
  methods: {
    loadNextPage() {
      this.getPosts({ page: this.page.current_page + 1 })
    },
    ...mapActions("post", ["getPosts"])
  }
}
</script>
