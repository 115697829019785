<template>
  <div
    class="modal fade"
    :id="'postImageModal' + postId"
    tabindex="-1"
    aria-labelledby="postImageModalLabel"
    aria-hidden="true"
    :ref="'postImageModal' + postId"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="postImageModalLabel">Post images</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            :id="'postSlider' + postId"
            class="carousel slide"
            data-bs-ride="carousel"
            :key="index"
          >
            <div class="carousel-indicators" v-if="data.length > 1">
              <button
                type="button"
                :data-bs-target="'#postSlider' + postId"
                :data-bs-slide-to="i"
                :class="{ active: i == index, 'aria-current': i == index }"
                :aria-label="'Slide' + i"
                v-for="(_, i) in data"
                :key="i"
              ></button>
            </div>
            <div class="carousel-inner">
              <div
                class="carousel-item"
                :class="{ active: i == index }"
                v-for="(item, i) in data"
                :key="i"
              >
                <AdvancedImage
                  :cldImg="item"
                  :plugins="imgPlugins"
                  class="rounded-3 mt-2"
                />
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              :data-bs-target="'#postSlider' + postId"
              data-bs-slide="prev"
              v-if="data.length > 1"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              :data-bs-target="'#postSlider' + postId"
              data-bs-slide="next"
              v-if="data.length > 1"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AdvancedImage } from "@cloudinary/vue"
export default {
  props: { postId: Number, index: Number, data: Array, imgPlugins: Array },
  components: {
    AdvancedImage
  }
}
</script>
