<template>
  <div
    class="modal fade"
    id="reportPostModal"
    ref="reportPostModal"
    tabindex="-1"
    aria-labelledby="reportPostModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <span
            class="position-absolute top-0 start-50 translate-middle p-3 bg-primary border border-light rounded-circle"
          >
            <i
              class="bi bi-exclamation-triangle-fill fs-4"
              style="color: white"
            ></i>
            <span class="visually-hidden">Alert</span>
          </span>

          <h5 class="modal-title" id="reportPostModalLabel">Report Post</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body">
          <div class="mb-3">
            {{ message }}
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-cancel rounded-pill px-4"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>

          <button
            data-bs-toggle="modal"
            :data-bs-target="'#reportPostDetailModal' + id"
            type="submit"
            class="btn btn-submit text-white rounded-pill px-4"
            data-bs-dismiss="modal"
          >
            Report
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    :id="'reportPostDetailModal' + id"
    tabindex="-1"
    aria-labelledby="reportPostDetailModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="reportPostDetailModal"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <span
            class="position-absolute top-0 start-50 translate-middle p-3 bg-primary border border-light rounded-circle"
          >
            <i
              class="bi bi-exclamation-triangle-fill fs-4"
              style="color: white"
            ></i>
            <span class="visually-hidden">Alert</span>
          </span>
          <h5 class="modal-title" id="reportPostDetailModalLabel">
            Report Post
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="closeButton"
          ></button>
        </div>
        <form @submit.prevent="submit">
          <div class="modal-body">
            <div class="mb-3 d-flex flex-column">
              <span class="mb-3 align-self-start">
                Please let us know why you are reporting this post's content?
              </span>
              <div
                class="form-check align-self-start"
                v-for="(option, i) in reportOptions"
                :key="i"
              >
                <input
                  class="form-check-input mb-2"
                  type="radio"
                  name="reportPostCheck"
                  :id="'reportPostCheck' + i"
                  :value="option"
                  v-model="reportChoice"
                />
                <label class="form-check-label" :for="'reportPostCheck' + i">
                  {{ option }}
                </label>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Additional text"
                aria-label="other"
                aria-describedby="basic-addon1"
                v-model="otherContent"
                v-if="isOther"
                required
              />
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-cancel px-4"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>

            <button type="submit" class="btn btn-submit text-white px-4">
              Report
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "ReportPostModal",
  props: {
    post_id: Number
  },
  data() {
    return {
      id: null,
      message: "Are you sure you want to report this post?",
      reportChoice: null,
      otherContent: "",
      reportOptions: [
        "Misleading Information",
        "Dangerous organizations and individuals",
        "Illegal activities and regulated goods",
        "Frauds and scams",
        "Violent and graphic content",
        "Animal cruelty",
        "Suicide, self-harm, and dangerous acts",
        "Hate Speech",
        "Harassment or Bullying",
        "Pornography and nudity",
        "Minor safety",
        "Spam",
        "Intellectual property infringement",
        "Other"
      ]
    }
  },
  created() {
    this.id = this.post_id
  },
  mounted() {
    let modal = this.$refs.reportPostModal
    modal.addEventListener("hidden.bs.modal", () => {
      this.closeModal()
    })
  },
  computed: {
    isOther() {
      return this.reportChoice === "Other"
    }
  },
  methods: {
    ...mapActions("post", ["reportPost"]),
    submit() {
      this.reportPost({ post_id: this.id, description: this.reportChoice })
      this.$refs.closeButton.click()
    },
    closeModal() {
      this.reportChoice = null
    }
  },
  watch: {
    post_id(data) {
      this.id = data
    }
  }
}
</script>

<style scoped>
.modal-title {
  font-size: 1.2rem;
  font-weight: 700;
}
</style>
