<template>
  <div class="mt-2" v-if="users">
    <div class="rounded-top bg-white shadow-sm mt-3 py-2"><b>People</b></div>
    <div
      class="rounded-3 bg-white shadow-sm mt-3 py-2 px-3"
      v-for="user in users"
      :key="user.id"
    >
      <div class="d-flex align-items-center justify-content-between">
        <profile-image :user="user" :size="63" class="me-3"></profile-image>
        <div class="ms-2">
          <h5 class="user-full-name">
            {{ user.first_name }} {{ user.last_name }}
            <i
              class="bi bi-patch-check-fill text-success ms-1"
              v-if="user.email_verified_at != null"
            ></i>
          </h5>
          <h5 class="user-name">@{{ user.first_name }}</h5>
        </div>
        <button
          class="btn rounded-pill px-3 following-btn"
          @click="unfollow({ friend_id: user.id })"
          v-if="isFollowing(user.id)"
        >
          Unfollow
        </button>
        <button
          class="btn rounded-pill px-3 follow-btn px-4"
          @click="follow({ friend_id: user.id })"
          v-else
        >
          Follow
        </button>
      </div>
    </div>
    <button
      type="button"
      class="btn btn-outline-primary my-4 w-100"
      @click="loadNextPage"
      v-if="page.last_page > page.current_page"
    >
      See more people
      <i class="bi bi-arrow-up-right-square-fill"></i>
    </button>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import ProfileImage from "@/components/profile/ProfileImage.vue"
export default {
  name: "SearchUsers",
  components: { ProfileImage },
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  mounted() {
    this.getFollowing()
  },
  computed: {
    ...mapState("user", ["page"]),
    ...mapState("follower", ["following"])
  },
  methods: {
    ...mapActions("user", ["getUsers"]),
    ...mapActions("follower", ["getFollowing", "unfollow", "follow"]),
    loadNextPage() {
      this.getUsers({ page: this.page.current_page + 1 })
    },
    isFollowing(userId) {
      for (const follow of this.following) {
        if (follow.friend_id === userId) {
          return true
        }
      }
      return false
    }
  }
}
</script>

<style scoped>
.user-full-name {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #000000;
}
.user-name {
  margin-top: -0.5rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000000;
}
.following-btn {
  font-weight: 600;
  font-size: 12px;
  color: #dadada;
  border: #ff411f solid 1px;
}

.follow-btn {
  background: linear-gradient(180deg, #ff411f 0%, #ff0bb9 100%);
  border-radius: 30px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: #ffffff;
}
</style>
