<template>
  <div class="container-fluid px-0">
    <div class="row g-1" v-if="data.length == 1">
      <AdvancedImage
        type="button"
        :cldImg="data[0]"
        :plugins="imgPlugins"
        data-bs-toggle="modal"
        :data-bs-target="'#postImageModal' + postId"
        @click="carouselIndex = 0"
      />
    </div>
    <div class="row g-1" v-if="data.length == 2">
      <div class="col-6" v-for="(img, i) in data" :key="img">
        <AdvancedImage
          type="button"
          :cldImg="img"
          :plugins="imgPlugins"
          class="w-100"
          data-bs-toggle="modal"
          :data-bs-target="'#postImageModal' + postId"
          @click="carouselIndex = i"
        />
      </div>
    </div>
    <div class="row g-1" v-if="data.length == 3">
      <div class="row g-1">
        <div class="col">
          <AdvancedImage
            type="button"
            :cldImg="data[0]"
            :plugins="imgPlugins"
            class="w-100"
            data-bs-toggle="modal"
            :data-bs-target="'#postImageModal' + postId"
            @click="carouselIndex = 0"
          />
        </div>
      </div>
      <div class="row g-1">
        <div class="col-6" v-for="(img, i) in data.slice(1)" :key="img">
          <AdvancedImage
            type="button"
            :cldImg="img"
            :plugins="imgPlugins"
            class="w-100"
            data-bs-toggle="modal"
            :data-bs-target="'#postImageModal' + postId"
            @click="carouselIndex = i"
          />
        </div>
      </div>
    </div>
    <div class="row g-1" v-if="data.length == 4">
      <div class="col-6" v-for="(img, i) in data" :key="img">
        <AdvancedImage
          type="button"
          :cldImg="img"
          :plugins="imgPlugins"
          class="w-100"
          data-bs-toggle="modal"
          :data-bs-target="'#postImageModal' + postId"
          @click="carouselIndex = i"
        />
      </div>
    </div>
  </div>
  <post-image-carousel
    :post-id="postId"
    :data="data"
    :img-plugins="imgPlugins"
    :index="carouselIndex"
  ></post-image-carousel>
</template>

<script>
import { AdvancedImage } from "@cloudinary/vue"
import PostImageCarousel from "./PostImageCarousel.vue"

export default {
  props: { postId: Number, data: Array, imgPlugins: Array },
  components: {
    AdvancedImage,
    PostImageCarousel
  },
  data() {
    return {
      carouselIndex: 0
    }
  }
}
</script>
