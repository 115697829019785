<template>
  <div
    class="d-flex align-items-center px-2 pt-3"
    style="border-top: 1px solid #e8eaed"
  >
    <profile-image :user="user" :size="40" class="me-3"></profile-image>

    <div class="input-group me-2">
      <textarea
        aria-label="Your comment"
        rows="1"
        class="form-control me-auto flex-grow-1"
        placeholder="Your comment"
        v-model="comment.content"
        @keyup.enter.exact="submitComment(post_id)"
        @keydown.enter.shift.exact.prevent="comment.content += '\n'"
        maxlength="250"
        @input="resize"
      ></textarea>
      <div class="input-group-text">
        <button
          class="btn px-1 py-0 d-block d-lg-none"
          @click="submitComment(post_id)"
        >
          <i class="bi bi-arrow-up-circle-fill fs-4 text-primary"></i>
        </button>
      </div>
    </div>
  </div>
  <login-modal ref="loginModal"></login-modal>
  <!-- other users comment -->
  <comment-delete-modal
    :deleted-item="deletedItem"
    @reset-delete="resetComment"
  ></comment-delete-modal>
  <div class="">
    <div class="d-flex px-2 mt-3" v-for="comment in comments" :key="comment.id">
      <profile-image
        :user="comment.user"
        :size="40"
        class="me-3"
      ></profile-image>
      <div class="d-flex flex-column">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex">
            <router-link
              :to="`/profile/${comment.user.id}`"
              style="text-decoration: none; color: inherit"
            >
              <span class="commenter-name">
                {{ comment.user.first_name }} {{ comment.user.last_name }}
                <i
                  class="bi bi-patch-check-fill text-success ms-1"
                  v-if="comment.user.email_verified_at != null"
                ></i>
              </span>
            </router-link>

            <span class="comment-time ms-2">
              {{ moment(comment.created_at).fromNow() }}</span
            >
          </div>
          <div class="dropdown" v-if="showOption(comment)">
            <button
              class="option-btn btn py-1 mx-2"
              type="button"
              id="commentActions"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots-vertical"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuComment">
              <li>
                <button class="dropdown-item" @click="editItem(comment)">
                  Edit Comment
                </button>
              </li>
              <li>
                <button
                  class="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#commentDeleteModal"
                  @click="removeComment(comment.id, comment.post_id)"
                >
                  Delete Comment
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="py-1 w-100 d-flex">
          <textarea
            aria-label="Your comment"
            class="form-control me-auto flex-grow-1"
            placeholder="Your Comment"
            v-show="false"
            v-model="editedComment.content"
            @keydown.esc="hideEditComment(comment)"
            @keydown.enter.shift.exact.prevent="editedComment.content += '\n'"
            @keyup.enter.exact="submitEditItem()"
            :ref="'commentArea' + comment.id"
            :id="'commentArea' + comment.id"
            maxlength="250"
            @input="resize"
          ></textarea>

          <span
            class="comment-text comment-box p-2 mt-2"
            style="white-space: pre-line"
            :id="'commentDetails' + comment.id"
            :ref="'commentDetails' + comment.id"
          >
            {{ comment.content }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import moment from "moment"
import { isAuth } from "@/utils/misc"
import { Modal } from "bootstrap"
import CommentDeleteModal from "@/components/post/CommentDeleteModal.vue"
import LoginModal from "@/components/LoginModal.vue"
import ProfileImage from "@/components/profile/ProfileImage.vue"

export default {
  name: "CommentSection",
  props: {
    comments: Array,
    post_id: Number,
    display: Boolean,
    showOption: Function
  },
  components: {
    CommentDeleteModal,
    LoginModal,
    ProfileImage
  },
  data() {
    return {
      comment: {
        content: null
      },
      editedComment: {
        id: null,
        content: null
      },
      deletedItem: {
        comment_id: null,
        post_id: null
      }
    }
  },
  created() {
    this.moment = moment
  },
  computed: {
    ...mapState("user", ["user"])
  },
  methods: {
    ...mapActions("comment", ["createComment", "editComment", "deleteComment"]),
    openLoginModal() {
      const modal = new Modal(this.$refs.loginModal.$refs.loginModal)
      modal.show()
    },
    submitComment(post_id) {
      if (!isAuth()) {
        this.openLoginModal()
        return
      }
      this.createComment({ comment: this.comment, post_id })
      this.comment.content = null
    },
    showEditComment(comment) {
      let commentArea = this.$refs[`commentArea${comment.id}`][0]
      commentArea.style.display = "initial"
      commentArea.style.height = `${commentArea.scrollHeight}px`

      this.$refs[`commentDetails${comment.id}`][0].style.display = "none"
    },
    hideEditComment(comment) {
      this.$refs[`commentArea${comment.id}`][0].style.display = "none"
      this.$refs[`commentDetails${comment.id}`][0].style.display = "initial"
    },
    editItem(comment) {
      this.showEditComment(comment)
      this.editedComment = Object.assign(
        {},
        { id: comment.id, content: comment.content }
      )
    },
    submitEditItem() {
      this.editComment(this.editedComment)
      this.hideEditComment(this.editedComment)
      this.editedComment = Object.assign({}, { id: null, content: null })
    },
    removeComment(commentId, postId) {
      this.deletedItem = Object.assign(
        {},
        {
          comment_id: commentId,
          post_id: postId
        }
      )
    },
    resetComment() {
      this.deletedItem = Object.assign({}, { comment_id: null, post_id: null })
    },
    resize(event) {
      event.target.style.height = "auto"
      event.target.style.height = `${event.target.scrollHeight}px`
    }
  }
}
</script>

<style scoped>
.form-control {
  background-color: #f6f9fc;
  border: 0px solid #f6f9fc;
  border-radius: 15px;
  height: 62px;
}
.form-control::placeholder {
  font-weight: 300;
  font-size: 15px;
  color: #6d6d6d;
  opacity: 1;
}

.input-group-text {
  background-color: #f6f9fc;
  border: 0px solid #f6f9fc;
  border-radius: 15px;
  height: 62px;
}

.comment-box {
  background: #f6f9fc;
  border-radius: 15px;
  min-height: 40px;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: justify;

  color: #6d6d6d;
}

.comment-text {
  text-align: justify;
  font-size: 0.8rem;
  background-color: #f6f9fc;
}
.commenter-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #000000;
}
.comment-time {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #c4c4c4;
}

.option-btn {
  background-color: rgba(223, 223, 223, 0.801);
  border-radius: 100px;
  width: 2rem;
  text-align: center;
  padding: 0px;
}

@media (max-width: 640px) {
  .comment-box {
    background-color: #eeeeee;
  }

  .comment-text {
    text-align: justify;
    font-size: 0.8rem;
  }
  .commenter-name {
    font-size: 1rem;
  }
  .comment-time {
    font-size: 0.8rem;
    color: #c0bebe;
  }
}

@media (min-width: 1024px) {
  .comment-box {
    background-color: #eeeeee;
  }
}
</style>
