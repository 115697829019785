<template>
  <div
    class="modal fade"
    id="loginModal"
    tabindex="-1"
    aria-labelledby="loginModalLabel"
    aria-hidden="true"
    ref="loginModal"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h4 class="modal-title text-sm" id="loginModalLabel">Login to Propelond</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body d-flex justify-content-center">
          <button
            class="btn btn-outline-primary w-75 my-8 rounded-pill d-none d-lg-block"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$router.push('login')"
          >
            <i class="bi bi-person"></i>
            Login with your email and password
          </button>
          <button
            class="btn btn-outline-primary w-75 my-8 rounded-pill d-block d-lg-none"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$router.push('login')"
          >
            <i class="bi bi-person"></i>
            Login to see more
          </button>
        </div>
        <div class="modal-footer">
         <span class="me-2"> Don't have an account ?</span>
          <button
            class="btn btn-dark btn-sm rounded-pill px-4"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$router.push('signup')"
          >
            Sign up
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginModal"
}
</script>

<style scoped>
.modal-title{
  font-size: 1rem;
}
</style>
