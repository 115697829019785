<template>
  <div
    class="modal fade"
    id="postModal"
    tabindex="-1"
    aria-labelledby="postModalLabel"
    aria-hidden="true"
    ref="postModal"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="p-3 border-0">
          <div
            class="d-flex align-items-center justify-content-between modal-title"
          >
            <div
              v-if="method == 'create'"
              class="d-flex align-items-center flex-grow-1"
              id="postModalLabel"
            >
              <profile-image
                :user="user"
                :size="40"
                :is-link="false"
                class="me-3"
              ></profile-image>

              <span class="modal-title" v-if="user">
                {{ user.first_name }} {{ user.last_name }}
                <i
                  class="bi bi-patch-check-fill text-success ms-1"
                  v-if="user.email_verified_at != null"
                ></i>
              </span>
            </div>
            <h5
              v-else
              class="modal-title"
              id="postModalLabel"
              style="font-weight: 700; font-size: 24px"
            >
              Edit post
            </h5>
            <button
              type="button"
              class="btn-close btn-sm"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModal"
              ref="closeButton"
            ></button>
          </div>
        </div>
        <form @submit.prevent="submitPost">
          <div class="modal-body">
            <div class="mb-3">
              <textarea
                class="form-control overflow-auto"
                ref="postDescription"
                v-model="postObj.description"
                placeholder="Compose something?"
                rows="6"
                required
                maxlength="1024"
                @input="resize"
              ></textarea>
              <div class="text-end" style="font-size: 0.8rem">
                Characters {{ postObj.description.length }}/1024
              </div>
              <div v-for="(imagePath, i) in imageUrls" :key="imagePath">
                <div style="position: relative">
                  <i
                    class="bi bi-x-circle-fill fs-4 rounded-circle px-1 close-file-btn"
                    style="position: absolute"
                    @click="clearImg(i)"
                  ></i>
                  <img :src="imagePath" class="w-100 my-4" alt="post-image" />
                </div>
              </div>

              <div v-for="(videoPath, i) in videoUrls" :key="videoPath">
                <div class="my-4">
                  <i
                    class="bi bi-x-circle-fill fs-4 rounded-circle px-1 close-file-btn"
                    style="float: right"
                    @click="clearVideo(i)"
                  ></i>
                  <video class="w-100" controls>
                    <source :src="videoPath" type="video/mp4" />

                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>

              <div v-if="errorMessage" class="text-danger">
                {{ errorMessage }}
              </div>
              <div
                class="d-flex align-items-center justify-content-between mt-2"
              >
                <div class="me-2">
                  <input
                    type="file"
                    v-show="false"
                    id="image"
                    ref="image"
                    accept="image/*"
                    @change="handleFile"
                  />
                  <button
                    type="button"
                    class="upload-btn d-flex align-items-center justify-content-center"
                    @click="$refs.image.click()"
                    :disabled="videoUrls.length > 0 || imageUrls.length == 4"
                  >
                    <i class="bi bi-file-image text-success fs-5 me-1"> </i>
                    <span>Add Photo</span>
                  </button>
                </div>
                <div class="me-2">
                  <input
                    type="file"
                    v-show="false"
                    id="video"
                    ref="video"
                    accept="video/*"
                    @change="handleFile"
                  />
                  <button
                    type="button"
                    class="upload-btn d-flex align-items-center justify-content-center"
                    @click="$refs.video.click()"
                    :disabled="imageUrls.length > 0 || videoUrls.length == 4"
                  >
                    <i class="bi bi-file-earmark-play text-success fs-5 me-1">
                    </i>
                    <span>Add Video</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0">
            <button type="submit" class="btn btn-publish rounded-pill px-5">
              Publish
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import ProfileImage from "@/components/profile/ProfileImage.vue"

export default {
  name: "PostModal",
  components: {
    ProfileImage
  },
  props: {
    user: Object,
    post: Object,
    method: {
      type: String,
      default: "create"
    }
  },
  data() {
    return {
      postObj: null,
      imageUrls: [],
      videoUrls: [],
      maxFileSize: 100 * 1024 * 1024, // 100MB
      errorMessage: null
    }
  },
  mounted() {
    let modal = this.$refs.postModal
    modal.addEventListener("hidden.bs.modal", () => {
      this.closeModal()
    })
    modal.addEventListener("shown.bs.modal", () => {
      let textarea = this.$refs.postDescription
      textarea.style.height = `${textarea.scrollHeight}px`
    })
  },
  created() {
    this.postObj = this.post
  },
  methods: {
    ...mapActions("post", ["createPost", "editPost"]),
    closeModal() {
      this.imageUrls = []
      this.videoUrls = []
      this.errorMessage = null
      this.$refs.image.value = ""
      this.$refs.video.value = ""
      this.$emit("reset-default")
    },
    handleFile(e) {
      let file = e.target.files[0]

      if (file.size > this.maxFileSize) {
        this.errorMessage = "File is too large. Cannot exceed 100MB"
        return
      }

      if (file.type.match("image.*")) {
        this.postObj.image_path.push(file)
        this.imageUrls.push(URL.createObjectURL(file))
      } else if (file.type.match("video.*")) {
        this.postObj.video_path.push(file)
        this.videoUrls.push(URL.createObjectURL(file))
      }
      this.errorMessage = null
    },
    submitPost() {
      let formData = new FormData()
      for (let imagePath of this.postObj.image_path) {
        formData.append("image_path[]", imagePath)
      }
      for (let videoPath of this.postObj.video_path) {
        formData.append("video_path[]", videoPath)
      }
      formData.append("description", this.postObj.description)

      if (this.method == "create") {
        this.createPost(formData)
        this.$refs.closeButton.click()
      } else {
        formData.append("id", this.postObj.id)
        this.editPost(formData)
        this.$refs.closeButton.click()
      }
    },
    clearImg(index) {
      this.imageUrls.splice(index, 1)
      this.postObj.image_path.splice(index, 1)
      this.$refs.image.value = ""
    },
    clearVideo(index) {
      this.videoUrls.splice(index, 1)
      this.postObj.video_path.splice(index, 1)
      this.$refs.video.value = ""
    },
    resize(event) {
      event.target.style.height = "auto"
      event.target.style.height = `${event.target.scrollHeight}px`
    }
  },
  watch: {
    post(data) {
      this.postObj = data
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";
.form-control {
  border: 1px solid #eeeeee;
  border-radius: 10px;
}

.form-control::placeholder {
  color: #c4c4c4;
  opacity: 1;
}

.upload-btn {
  outline: none;
  border: none;
  background-color: white;
}
.close-file-btn {
  top: 30px;
  right: 9px;
  cursor: pointer;
  color: #ffc107;
}

.close-file-btn:hover {
  color: #adb5bd;
}

.btn-publish {
  background: $primary-gradient;
  border-radius: 30px;
  color: white;
}
</style>
